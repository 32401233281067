import React, { useEffect, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DataControl,
  DataList,
  DeleteButtonDiv,
  EditButtonDiv,
  FileInput,
  ScrollSec,
  Table,
  TableRow,
  UpdateButton,
  UpdateInputs,
} from "../style";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import { CaravanAddModal } from "../modal";
import UpdateIcon from "@mui/icons-material/Update";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";

const UserData = () => {
  const [data, setData] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [newName, setNewName] = useState();
  const [newEmail, setNewEmail] = useState();

  
  const [update, setUpdate] = useState(Array(data.length).fill(false));

  useEffect(() => {
    fetchData();
  });

  const fetchData = async () => {
    try {
      const response = await axios.get("https://api-camper.inomjonov.site/api/sign-up");
      setData(response.data);
    } catch (error) {
      console.log("failed to fetch data", error);
    }
  };

  
  const handleChange = (e) => {
    setName(e.target.value);
    console.log("name is clicked");
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    console.log("name is clicked");
  };
  
  const handleEdit = async (id) => {
    try {
      console.log('edit1')
      const response = await fetch(
        `https://api-camper.inomjonov.site/api/sign-up/${id}`,
        {
          method: "PUT",
          headers:{
            "Content-Type":"application/json"
          },
          body: JSON.stringify({
            newName,
            newEmail
          })
        }
      );
      if(response.ok){
        console.log('edit2')
      }else{
        console.log('res is not ok')
      }

      setNewName("");
      fetchData();
    } catch (error) {
      console.error("error editing");
    }
  };

  const handleDelete = async (id) => {
    try {
        const response = await fetch(`https://api-camper.inomjonov.site/api/user/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        });
        if (response.ok) {
            console.log('Data deleted successfully');
            fetchData();
        } else {
            console.error('Failed to delete data');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
  const handleClick = (index) => {
    const newEditMode = [...update];
    newEditMode[index] = !newEditMode[index];
    setUpdate(newEditMode);
  };

  return (
    <div>
      <DataList className="tablet">
        <DataControl>
          <b>User list</b>
        </DataControl>
        <ScrollSec>
          <Table>
            <TableRow $user>
              <th style={{display:'flex'}}>No.</th>
              <th>User</th>
              <th>Email</th>
              <th>Edit/Delete</th>
            </TableRow>
            {data.map((value, index) => {
              return (
                <TableRow $user  key={index}>
                  {update[index] ? (
                    <>
                      <td>{index + 1}</td>
                      <td>
                        <UpdateInputs
                          type="text"
                          name="name"
                          onChange={(e) => setNewName(e.target.value)}
                          placeholder={value.name}
                        />
                      </td>
                      <td>
                        <UpdateInputs
                          type="text"
                          name="email"
                          onChange={(e) => setNewEmail(e.target.value)}
                          placeholder={value.email}
                        />
                      </td>
                      
                      <td>
                        <UpdateButton
                          onClick={(e) => {
                            handleEdit(value._id);
                            handleClick(index);
                          }}
                        >
                          <SaveAsOutlinedIcon />
                        </UpdateButton>
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={{ display:'flex'}}>{index + 1}</td>
                   
                      <td>{value.name}</td>
                      <td>{value.email || "no data"}</td>
                      <td style={{ display: "flex", gap: "10px", justifyContent:"center" }}>
                        <EditButtonDiv onClick={() => handleClick(index)}>
                          <EditIcon sx={{ fill: "white" }} />
                        </EditButtonDiv>
                        <DeleteButtonDiv
                          onClick={() => handleDelete(value._id)}
                        >
                          <DeleteIcon sx={{ fill: "white" }} />
                        </DeleteButtonDiv>
                      </td>
                    </>
                  )}
                </TableRow>
              );
            })}
          </Table>
        </ScrollSec>
      </DataList>
    </div>
  );
};

export default UserData;
