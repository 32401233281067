import React, { useState } from "react";
import styled from "styled-components";
import BgImg from "./assets/login.jpeg";
import {
  LogInForm,
  LogInInputs,
  LongDash,
  SignInnerDiv,
  SignOr,
  SignTypography,
  SocialIcon,
  SocialIcons,
} from "./style";
import { SLink } from "./page/linkStyle";
import SizeCheckboxes from "./materials/checkbox";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import OAuth from "./components/OAuth";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: white;
  align-items: center;
`;

const Bg = styled.div`
  background-image: url(${BgImg});
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;
const LoginSec = styled.div`
  width: 500px;
  margin-inline: auto;
`;

const Heading = styled.h1`
  font-family: "Edu AU VIC WA NT Hand";
  font-size: 50px;
  color: #006dab;
  margin-bottom: 30px;
`;

const Login = () => {
  const [formData, setFormData] = useState({});
  const [email, setEmail] = useState(); //should be deleted before publishing
  const [password, setPassword] = useState(); // should be deleted before publishing
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email !== "") {
      try {
        const response = await fetch("https://api-camper.inomjonov.site/api/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const data = await response.json();
        const access = data.token;
        console.log(data)
        if (response.status === 201) {
          alert(`Welcom ${data.name}`);
          navigate("/dashboard");
          setError("");
          localStorage.setItem("token", access);
          console.log(access)
        } else {
          setError("Incorrect email or password.");
          alert("Incorrect email or password.");
        }
      } catch (error) {
        console.error("login error cought", error);
        if (error.response && error.response.status === 400) {
          setError("Incorrect email or password.");
          alert("Incorrect email or password.");
        } else {
          setError("An error occurred. Please try again.");
          alert("An error occurred. Please try again.");
        }
      }
    }
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <Container>
      <Bg></Bg>
      <div>
        <div>
          <h3>For demo website showcase</h3>
          <p>Email: <b>camper@mail.ru</b></p>
          <p>Password: <b>camper</b></p>
        </div>
        <LoginSec>
          <Heading> Welcome to Caravan</Heading>
          <h1
            style={{
              marginBottom: "10px",
              color: "#006dab",
              textAlign: "center",
            }}
          >
            Login with email
          </h1>
          {error && <div style={{ color: "red" }}>{error}</div>}
          <LogInForm onSubmit={handleSubmit}>
            <label>Your email</label>
            <LogInInputs
              type="text"
              placeholder="example@gmail.com"
              id="email"
              onChange={handleChange}
            />
            <label>Your password</label>
            <LogInInputs
              type="password"
              placeholder="Your password"
              id="password"
              onChange={handleChange}
            />
            <SignInnerDiv style={{ marginBottom: "10px" }}>
              <SizeCheckboxes />
              <SignTypography>Keep me Logged in</SignTypography>
              <SignTypography>Forgot your password?</SignTypography>
            </SignInnerDiv>
            <Button
              type="submit"
              sx={{ width: "100%", height: "50px" }}
              variant="contained"
            >
              SIGN IN
            </Button>
          </LogInForm>
          <SignOr $or>
            <LongDash></LongDash> OR <LongDash></LongDash>
          </SignOr>
          <SocialIcons>
            <SocialIcon><OAuth/></SocialIcon>
          </SocialIcons>
          <SLink to="/register">
            <Button
              sx={{ width: "100%", height: "50px", marginTop: "30px" }}
              variant="outlined"
            >
              CREATE ACCOUNT
            </Button>
          </SLink>
        </LoginSec>
      </div>
    </Container>
  );
};

export default Login;
