import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DataControl,
  DataList,
  DeleteButtonDiv,
  EditButtonDiv,
  FileInput,
  FilterButton,
  Input,
  InputsDiv,
  ScrollSec,
  Table,
  TableRow,
  UpdateButton,
  UpdateInputs,
} from "../style";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import MotorAddModal from "../modal";
import UpdateIcon from "@mui/icons-material/Update";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { app } from "../../../firebase";

const MotorData = () => {
  const token = localStorage.getItem("token");

  console.log(token, "this is token");

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [name, setName] = useState();
  const [company, setCompany] = useState();
  const [license, setLicense] = useState();
  const [passanger, setPassanger] = useState();
  const [cost, setcost] = useState();
  const [type, setType] = useState();
  const [date, setDate] = useState("");
  const [rating, setRating] = useState();
  const [location, setLocation] = useState();
  const [newName, setNewName] = useState();
  const [newCompany, setNewCompany] = useState();
  const [newLicense, setNewLicense] = useState();
  const [newPassanger, setNewPassanger] = useState();
  const [newCost, setNewcost] = useState();
  const [newType, setNewType] = useState();
  const [newDate, setNewDate] = useState();
  const [newRating, setNewRating] = useState();
  const [newLocation, setNewLocation] = useState();
  const [update, setUpdate] = useState(Array(data.length).fill(false));

  // upload image
  const { currentUser } = useSelector((state) => state.user);
  const [file, setFile] = useState(undefined);
  const [filePerc, setFilePers] = useState(0);
  const [errorUploadImg, setErrorUploadImg] = useState(false);
  const dispatch = useDispatch();

  console.log('file is ',file)

  useEffect(() => {
    if (file) {
      handleFileUpload(file);
    }
  }, [file]);
  const handleFileUpload = (file) => {
    return new Promise((resolve, reject) => {
        const storage = getStorage(app); // we call app from firebase to identify which storage to call
        const fileName = new Date().getTime() + file.name; // adding date to file name prevents double submission of same file name
        const storageRef = ref(storage, fileName);
        const uploadTask = uploadBytesResumable(storageRef, file); // to monitor process of uploading a file

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setFilePers(Math.round(progress));
            },
            (error) => {
                setErrorUploadImg(true);
                alert("Uploading image error front!");
                reject(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });
            }
        );
    });
};


  const fetchData = async () => {
    try {
      const response = await fetch("https://api-camper.inomjonov.site/motor", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Error fetching data 'frontend'");
      }
      const motor = await response.json();
      setData(motor);
      setFilteredData(motor);
    } catch (error) {
      console.error("failed to fetch data", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name !== '') {
        if (!token) {
            console.log('no token in the 84');
            return;
        }
        try {
            let imageUrl = '';
            if (file) {
                imageUrl = await handleFileUpload(file);
            }

            const response = await fetch("https://api-camper.inomjonov.site/motor", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    name,
                    company,
                    cost,
                    rating,
                    passanger,
                    type,
                    date,
                    location,
                    license,
                    image: imageUrl, // Include the image URL
                })
            });

            const motor = await response.json();
            if (response.ok) {
                fetchData();
                setName("");
                setCompany("");
                setcost("");
                setLocation("");
                setPassanger("");
                setType("");
                setDate("");
                setLicense("");
                setRating("");
                setFile(undefined); // Reset file input
            }
        } catch (error) {
            console.error("failure", error);
        }
    }
};


  //filter
  const handleSearch = (query) => {
    if (query && typeof query === "string") {
      const filtered = Array.isArray(data)
        ? data.filter((motor) => {
            return (
              motor.name &&
              typeof motor.name === "string" &&
              motor.name.toLowerCase().includes(query.toLowerCase())
            );
          })
        : [];
      setFilteredData(filtered);
      console.log("search is working", filtered);
    } else {
      setFilteredData(data);
      console.log("query is not filtering");
    }
  };
  //filter ends

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };
  const handleImageUpload = (setter) => (e) => {
    setter(e.target.files[0]);
  };

  const handleEdit = async (id) => {
    try {
      let imageUrl = '';
      if(file){
        imageUrl = await handleFileUpload(file);
      }
      const res = await fetch(`https://api-camper.inomjonov.site/motor/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          newName,
          newCompany,
          newLicense,
          newPassanger,
          newCost,
          newType,
          newDate,
          newRating,
          newLocation,
          image: imageUrl
        }),
      });
      setNewName("");
      fetchData();
    } catch (error) {
      console.error("error editing");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://api-camper.inomjonov.site/motor/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.ok) {
        console.log("Data deleted successfully");
        fetchData();
      } else {
        console.error("Failed to delete data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClick = (index) => {
    const newEditMode = [...update];
    newEditMode[index] = !newEditMode[index];
    setUpdate(newEditMode);
  };
  const handleChangeNewName = (e) => {
    setNewName(e.target.value);
  };

  return (
    <div>
      <DataList className="tablet">
        <DataControl>
          <h2>Motor list</h2>
          <InputsDiv>
            <Input
              type="text"
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Type to search..."
            />
            <SearchIcon />
          </InputsDiv>
          <FilterButton>
            <SortOutlinedIcon /> Filter
          </FilterButton>
          <MotorAddModal
            onSubmit={handleSubmit}
            names={{
              name,
              company,
              license,
              cost,
              passanger,
              type,
              date,
              location,
              rating,
            }}
            handlers={{
              onClick: handleChange(setName),
              onCompany: handleChange(setCompany),
              onLicense: handleChange(setLicense),
              onPassanger: handleChange(setPassanger),
              onCost: handleChange(setcost),
              onDate: handleChange(setDate),
              onType: handleChange(setType),
              onRating: handleChange(setRating),
              onLocation: handleChange(setLocation),
              onImage: handleImageUpload(setFile),
            }}
          />
        </DataControl>
        <ScrollSec>
          <Table>
            <TableRow
              style={{ position: "sticky", top: "0", backgroundColor: "white" }}
            >
              <th /* style={{paddingRight:'70px'}} */>No.</th>
              <th>Car image</th>
              <th>Name</th>
              <th>Company</th>
              <th>License</th>
              <th>Passengers</th>
              <th>Cost</th>
              <th>Type</th>
              <th>Date</th>
              <th>Rating</th>
              <th>Location</th>
              <th>Edit/Delete</th>
            </TableRow>
            {Array.isArray(filteredData) && filteredData.map((value, index) => {
              return (
                <TableRow
                  key={value._id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#d8d8d836" : "white",
                  }}
                >
                  {update[index] ? (
                    <>
                      <td>{index + 1}</td>
                      <td>
                        <FileInput type="file" name='image' accept="image/*" onChange={(e) => setFile(e.target.files[0])}/>
                      </td>
                      <td>
                        <UpdateInputs
                          type="text"
                          name="name"
                          onChange={handleChangeNewName}
                          placeholder={value.name}
                        />
                      </td>
                      <td>
                        <UpdateInputs
                          type="text"
                          name="company"
                          onChange={(e) => setNewCompany(e.target.value)}
                          placeholder={value.company}
                        />
                      </td>
                      <td>
                        <UpdateInputs
                          type="text"
                          name="license"
                          onChange={(e) => setNewLicense(e.target.value)}
                          placeholder={value.license}
                        />
                      </td>
                      <td>
                        <UpdateInputs
                          type="text"
                          name="passanger"
                          onChange={(e) => setNewPassanger(e.target.value)}
                          placeholder={value.passanger}
                        />
                      </td>
                      <td>
                        <UpdateInputs
                          type="number"
                          name="cost"
                          onChange={(e) => setNewcost(e.target.value)}
                          placeholder={value.cost}
                        />
                      </td>
                      <td>
                        <UpdateInputs
                          type="text"
                          name="type"
                          onChange={(e) => setNewType(e.target.value)}
                          placeholder={value.type}
                        />
                      </td>
                      <td>
                        <UpdateInputs
                          type="date"
                          name="date"
                          onChange={(e) => setNewDate(e.target.value)}
                          placeholder={value.date}
                        />
                      </td>
                      <td>
                        <UpdateInputs
                          type="number"
                          name="rating"
                          onChange={(e) => setNewRating(e.target.value)}
                          placeholder={value.rating}
                        />
                      </td>
                      <td>
                        <UpdateInputs
                          type="text"
                          name="location"
                          onChange={(e) => setNewLocation(e.target.value)}
                          placeholder={value.location}
                        />
                      </td>
                      <td>
                        <UpdateButton
                          onClick={(e) => {
                            handleEdit(value._id);
                            handleClick(index);
                          }}
                        >
                          <SaveAsOutlinedIcon />
                        </UpdateButton>
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={{ paddingLeft: "10px" }}>{index + 1}</td>
                      <td>
                        {/* <img src={value.photo} alt="" width={50}/> */}
                        <div>
                          <img src={value.image} alt="car image" height={30} />
                        </div>
                      </td>
                      <td>{value.name}</td>
                      <td>{value.company || "no data"}</td>
                      <td>{value.license || "no license"}</td>
                      <td>{value.passanger || "no passanger"}</td>
                      <td>{value.cost}$</td>
                      <td>{value.type}</td>
                      <td>{value.date}</td>
                      <td>{value.rating}</td>
                      <td>{value.location}</td>
                      <td style={{ display: "flex", gap: "10px" }}>
                        <EditButtonDiv
                          onClick={() => {
                            handleClick(index);
                            setNewName(value.name);
                          }}
                        >
                          <EditIcon sx={{ fill: "white" }} />
                        </EditButtonDiv>
                        <DeleteButtonDiv
                          onClick={() => {
                            handleDelete(value._id);
                            console.log("id hsould be deleted", value._id);
                          }}
                        >
                          <DeleteIcon sx={{ fill: "white" }} />
                        </DeleteButtonDiv>
                      </td>
                    </>
                  )}
                </TableRow>
              );
            })}
          </Table>
        </ScrollSec>
      </DataList>
    </div>
  );
};

export default MotorData;
